import React from 'react'
import { ScoreScale } from '../components/library'

function CACTScale({hideBar = false}) {
    return (
        <ScoreScale
            hideBar={hideBar}
            levels={[
                {value: 13, label: '0-13 poorly controlled', level: 0},
                {value: 20, label: '14-20 partially controlled', level: 1},
                {value: 27, label: '21-27 well controlled', level: 4},
            ]}
        />
    )
}

export default CACTScale
