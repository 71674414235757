import * as React from 'react'
import { useState } from 'react'
import { navigate } from 'gatsby'
import { DateTime } from 'luxon'
import { Entity, SubmitButtonComponent } from '@parallelpublicworks/entitysync'
import { Typography, CardTurn, Modal, Actions, Button, Spacer, CircularProgress } from '../components/library'
import { RadioField } from '../components/library/entitysync'
import { questionArray } from '../utils/cact-scoring'
import { ImpactContext } from '../components/Layout'
import CACTInfo from '../components/CACTInfo'
import setBonesToBeAdded from '../utils/setBonesToBeAdded'

const SubmitButton = SubmitButtonComponent(props => {
  return <Button onClick={props.onClick} {...props} />
})

const CActFormPage = ({ location }) => {
  const forceBackTo = location.state ? location.state.forceBackTo : null
  const { userData, currentWeek, profiles, today, isPastWeek, previousWeek } = React.useContext(ImpactContext)

  const [questionNum, setQuestionNum] = useState(0)
  const [turn, setTurn] = useState('child')
  const [isLastQuestion, setIsLastQuestion] = useState(false)
  const [radioAttrs, setRadioAttrs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [justOpen, setJustOpen] = useState(true)
  const [userDataChangesCount, setUserDataChangesCount] = React.useState(0)
  const [cACTDate, setCACTDate] = useState(null)
  const [backTo, setBackTo] = useState(null)
  const [somethingSelected, setSomethingSelected] = useState(false)
  const [boneID, setBoneID] = useState(null)
  const [goingAway, setGoingAway] = useState(false)

  React.useEffect(() => {
    if (forceBackTo) {
      setBackTo(forceBackTo)
    } else {
      setBackTo(isPastWeek && currentWeek.todo_left === 1 ? '/' : '/weekly-to-do')
    }
    setBoneID(DateTime.now().toISO())
  }, [])

  React.useEffect(() => {
    if (currentWeek !== null && cACTDate === null) {
      setCACTDate(currentWeek.interval.contains(today) ? today : currentWeek.interval.e.plus({ minutes: -1 }))
    }
  }, [currentWeek])

  React.useEffect(() => {
    if (loading) {
      setUserDataChangesCount(userDataChangesCount + 1)
    }
  }, [userData, loading])

  React.useEffect(() => {
    if (userDataChangesCount >= 3 && !goingAway) {
      setGoingAway(true)
      if (userData !== null) {
        const stored_cact_response = Object.keys(userData).find(obj_uuid => {
          const obj = userData[obj_uuid]
          return obj?.attributes?.field_test_date_iso === cACTDate.toISO() && obj.type === 'node--cact_response'
        })
        if (stored_cact_response) {
          const bones_to_add = previousWeek && previousWeek.todo_done ? 3 : 2
          // console.warn('bones_to_add after c-act', bones_to_add, 'because', previousWeek?.todo_done);
          setBonesToBeAdded(bones_to_add, boneID, 'Great job completing the C-ACT!', true)
          navigate('/c-act-detail-view', { state: { response: userData[stored_cact_response], backTo } })
        }
      }
    }
  }, [userDataChangesCount])

  React.useEffect(() => {
    if (loading) {
      setRadioAttrs({ ...radioAttrs, disabled: true })
    }
  }, [loading])

  React.useEffect(() => {
    let question = questionArray[questionNum]
    let attrs = {
      field: question.field,
      key: question.field
    }
    if (question.input === 'mood') {
      attrs.mood = true
      attrs.labels = question.labels
      if (question.icons) {
        attrs.icons = question.icons
      }
    } else {
      attrs.hideRadio = true
      attrs.options = question.labels.map((lbl, i) => ({
        value: i,
        label: lbl
      }))
    }
    setRadioAttrs(attrs)
    setTurn(question.turn)
  }, [questionNum])

  function moveStep(steps) {
    let to = questionNum + steps
    if (to < 0) to = 0
    if (to >= questionArray.length - 1) to = questionArray.length - 1
    setIsLastQuestion(to === questionArray.length - 1)
    setQuestionNum(to)
  }

  function onSubmit(e, unsavedChanges, entityData) {
    setUserDataChangesCount(0)
    if (cACTDate === null) {
      e.preventDefault()
    } else if (unsavedChanges && unsavedChanges.attributes) {
      unsavedChanges.attributes.title = 'CACT Result'
      unsavedChanges.attributes.field_test_date_iso = cACTDate.toISO()
      if (isPastWeek) {
        unsavedChanges.attributes.field_test_week = `week${currentWeek.number - 1}`
      } else {
        unsavedChanges.attributes.field_test_week = previousWeek ? `week${previousWeek.number}` : `week${currentWeek.number - 1}`
      }
    }
    setLoading(true)
  }

  if (currentWeek === null) return <CircularProgress />

  if (currentWeek.cact_done)
    return (
      <Modal id="cact-form-modal" title="C-ACT" handleClose={e => navigate(-1)} open={true} size="xs">
        <Typography variant="body1" align="left">
          {`You already completed the C-ACT for this week.`}
        </Typography>
      </Modal>
    )

  if (justOpen)
    return (
      <Modal
        open={true}
        handleClose={() => navigate(-1)}
        title="C-ACT"
        footer={
          <Button onClick={e => setJustOpen(false)} variant="contained" color="primary">
            Take C-ACT Now
          </Button>
        }
      >
        <CACTInfo />
      </Modal>
    )

  return (
    <Entity type="node--cact_response" componentId="cact-response-entity">
      {radioAttrs !== null && (
        <Modal
          id="cact-form-modal"
          open={true}
          title="Asthma Control Test"
          subtitle={`IMPACT Week ${currentWeek.number + 1}, ${currentWeek.label}`}
          handleClose={e => navigate(`/`)}
          footer={
            <Actions
              dismissOnClick={e => moveStep(-1)}
              dismissLabel="Previous"
              dismissDisabled={!questionNum || loading}
              submitOnClick={e => {
                if (!isLastQuestion) {
                  setSomethingSelected(false)
                  moveStep(+1)
                }
              }}
              submitDisabled={!somethingSelected}
              submitButton={
                <SubmitButton
                  variant="contained"
                  onSubmit={onSubmit}
                  disabled={loading || !somethingSelected}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </SubmitButton>
              }
              submitLabel="Next"
              currentStep={questionNum + 1}
              totalSteps={questionArray.length}
              lastStep={isLastQuestion}
            />
          }
        >
          <CardTurn profile={profiles[turn]} description={questionArray[questionNum].label} shade={turn === 'child' ? 'light' : 'dark'} />
          <RadioField {...radioAttrs} onChange={e => setSomethingSelected(true)} />
          <Spacer amount="0" />
        </Modal>
      )}
    </Entity>
  )
}

export default CActFormPage
