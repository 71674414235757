function setBonesToBeAdded(
    bones, 
    id,
    description = 'Good job! Keep it up!',
    overAll = false,
    title,
    boneIcon
){
    
    if(typeof window !== 'undefined'){
        let awarded_bones = localStorage.getItem('awarded_bones')
        awarded_bones = awarded_bones ? JSON.parse(awarded_bones) : null
        if(!awarded_bones || (awarded_bones && !awarded_bones.includes(id))){
            title = title || `You earned ${bones} ${bones === 1 ? 'bone' : 'bones'}!`
            const obj = {
                description,
                title,
                actionLabel: 'Ok',
                id,
                overAll
            }
            if(bones){
                obj.bones = bones
            }
            if(boneIcon){
                obj.boneIcon = true
            }
            localStorage.setItem('add_bone', JSON.stringify(obj))
        }
    }
}

export default setBonesToBeAdded
