export function calculateScore(response){
  let score = 0;
  if(typeof response.attributes !== 'undefined' && response.attributes){
    for(let key of Object.keys(response.attributes)){
      if(key.endsWith("score")){
        score += parseInt(response.attributes[key]);
      }
    }
  }
  return score;
}

export const questionArray = [
  {
    label: '1. How is your asthma today?',
    field: 'field_asthma_today_score',
    turn: 'child',
    input: 'mood',
    labels: [
      'Very bad (+0)',
      'Bad (+1)',
      'Good (+2)',
      'Very good (+3)',
    ],
    icons: [
        'very-bad',
        'bad',
        'good',
        'very-good',
    ]
  },
  {
    label: '2. How much of a problem is your asthma when you run, exercise or play sports?',
    field: 'field_exercise_score',
    turn: 'child',
    input: 'mood',
    labels: [
      'It’s a big problem. I can’t do what I want to do. (+0)',
      'It’s a problem and I don’t like it. (+1)',
      'It’s a little problem but it’s okay. (+2)',
      'It’s not a problem (+3)',
    ]
  },
  {
    label: '3. Do you cough because of your asthma?',
    field: 'field_coughing_score',
    turn: 'child',
    input: 'mood',
    labels: [
      'Yes, all of the time. (+0)',
      'Yes, most of the time. (+1)',
      'Yes, some of the time. (+2)',
      'No, none of the time. (+3)'
    ]
  },
  {
    label: '4. Do you wake up during the night because of your asthma?',
    field: 'field_waking_score',
    turn: 'child',
    input: 'mood',
    labels: [
      'Yes, all of the time. (+0)',
      'Yes, most of the time. (+1)',
      'Yes, some of the time. (+2)',
      'No, none of the time. (+3)'
    ]
  },
  {
    label: '5. During the last 4 weeks, how many days did your child have any daytime asthma symptoms?',
    field: 'field_daytime_symptom_days_score',
    turn: 'parent',
    labels: [
      'Everyday (+0)',
      '19 - 24 days / month (+1)',
      '11- 18 days / month (+2)',
      '4-10 days / month (+3)',
      '1- 3 days / month (+4)',
      'Not at all (+5)'
    ]
  },
  {
    label: '6. During the last 4 weeks, how many days did your child wheeze during the day because of asthma?',
    field: 'field_wheezing_days_score',
    turn: 'parent',
    labels: [
      'Everyday (+0)',
      '19 - 24 days / month (+1)',
      '11- 18 days / month (+2)',
      '4-10 days / month (+3)',
      '1- 3 days / month (+4)',
      'Not at all (+5)'
    ]
  },
  {
    label: '7. During the last 4 weeks, how many days did your child wake up during the night because of asthma?',
    field: 'field_waking_days_score',
    turn: 'parent',
    labels: [
      'Everyday (+0)',
      '19 - 24 days / month (+1)',
      '11- 18 days / month (+2)',
      '4-10 days / month (+3)',
      '1- 3 days / month (+4)',
      'Not at all (+5)'
    ]
  },
]
