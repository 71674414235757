import React from 'react'
import CACTScale from './CACTScale'
import Typography from './library/Typography'

function CACTInfo() {
    return (
        <>
            <Typography variant="subtitle2" shade="dark">What is C-ACT?</Typography>
            <Typography shade="dark">The Childhood Asthma Control Test (C-ACT) has 7 questions to assess asthma control.</Typography>
            <Typography variant="subtitle2" shade="dark">How to take the test?</Typography>
            
            <Typography shade="dark"><strong>1.</strong> Tap the test button to begin.</Typography>
            <Typography shade="dark"><strong>2.</strong> The first 4 questions are for the child. If he/she needs help reading or understanding the question, the parent may help, but let the child select the response.</Typography>
            <Typography shade="dark"><strong>3.</strong> The parent then completes the remaining three questions (5 to 7) There are no right or wrong answers.</Typography>
            <Typography shade="dark"><strong>4.</strong> Click submit and you'll receive your C-ACT score.</Typography>
            
            <Typography variant="subtitle2" shade="dark">What does the score mean?</Typography>
            <Typography shade="dark">The ACT score ranges from 0 to 27. Higher scores indicate better control.</Typography>
            <CACTScale />
        </>
    )
}

export default CACTInfo
